import { ReactComponent, FC } from "src/types";
import { cx } from "src/utils";
import styles from "./styles.module.scss";
import { useReactToPrint } from "react-to-print";
import { CvPage as Page } from "../CvPage";
import { useCallback, useRef } from "react";
import { Translator, useLocalization } from "src/lang";

interface MainLayoutProps extends ReactComponent {}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { direction } = useLocalization();
  const printContentRef = useRef<any>(null);
  const printContent = useCallback(() => {
    return printContentRef.current;
  }, [printContentRef.current]);

  const handlePrint = useReactToPrint({
    content: printContent,
    bodyClass: `print-page-${direction}`,
  });

  return (
    <main className={cx(styles.layout)}>
      <Page ref={printContentRef}>{children}</Page>
      <button onClick={handlePrint} className={cx(styles.button)}>
        <Translator textKey="save" />
      </button>
    </main>
  );
};

export default MainLayout;
