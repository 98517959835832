import React from "react";
import { ErrorMessage } from "../ErrorMessage";
import styles from "./styles.module.scss";
import { cx } from "src/utils";

const Forbidden = () => {
  return (
    <div className={cx(styles.forbidden)}>
      <ErrorMessage status="403" message="forbidden" />
    </div>
  );
};

export default Forbidden;
