export const lang = {
  en: {
    contact: "Contact",
    education: "Education",
    certificates: "Certificates",
    languages: "Languages",
    experience: "Experience",
    skills: "Skills",
    save: "Save",
    phone: "Phone",
    email: "Email",
    address: "Address",
    forbidden: "You can not reach this page",
    notFound: "The page you requested is not found",
    watermark: "Created By Wadhifety",
    about: "About",
  },
  ar: {
    contact: "معلومات  الاتصال",
    education: "التعليم",
    certificates: "الشهادات",
    languages: "اللغات",
    experience: "الخبرة",
    skills: "المهارات",
    save: "حفظ",
    phone: "رقم الهاتف",
    email: "البريد الالكتروني",
    address: "العنوان",
    forbidden: "لا يمكنك الوصول الى هذه الصفحة",
    notFound: "لم يتم العثورعلى هذه الصفحة",
    watermark: "تم الانشاء بواسطة وظيفتي",
    about: "الوصف",
  },
};

/**
 * Get language object for code param.
 * @param {string} code code for language, `default is "en"`
 * @returns
 */
export function getLang(code: ILocal = "en"): ILangObj {
  return lang[code];
}

export type ILang = keyof typeof lang.en;
export type ILangObj = typeof lang.en;
export type ILocal = keyof typeof lang;
