import React from "react";
import { FC } from "src/types";

interface IFProps {
  condition: any;
  children: React.ReactNode;
}

/**
 * Return chiledren if conditin is truthy. Otherwise return null
 */
const IF: FC<IFProps> = ({ condition, children }) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};

export default IF;
