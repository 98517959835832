import { ILang, Translator } from "src/lang";
import { FC } from "src/types";
import styles from "./styles.module.scss";
import { cx } from "src/utils";

interface ErrorProps {
  status: "403" | "404";
  message: ILang;
}

const ErrorMessage: FC<ErrorProps> = ({ status = "403", message }) => {
  return (
    <div className={cx(styles.error_mssage)}>
      <span className={cx(styles.status, "heading")}>
        <span>{status[0]}</span>
        <span className={cx(styles.colorNumber)}>{status[1]}</span>
        <span>{status[2]}</span>
      </span>
      <p className={cx(styles.message, "text")}>
        <Translator textKey={message} />
      </p>
    </div>
  );
};

export default ErrorMessage;
