import { FC } from "src/types";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthentecateSeeker, LocalizationSetting } from "src/components";
import { LocalizationProvider } from "src/lang";

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
    <Router>
      <AuthentecateSeeker>
        <LocalizationProvider>
          <LocalizationSetting>{children}</LocalizationSetting>
        </LocalizationProvider>
      </AuthentecateSeeker>
    </Router>
  );
};

export default Providers;
