import { createContext, useContext } from "react";
import { ISeeker } from "./types";
import { useFetch } from "src/hooks";
import { FC } from "src/types";
import Loader from "src/components/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";

interface AuthentecateSeekerProps {
  children: React.ReactNode;
}

const SeekerContext = createContext<ISeeker | null>(null);

const AuthentecateSeeker: FC<AuthentecateSeekerProps> = ({ children }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { data, isFetching } = useFetch<ISeeker>("/user/info", {
    onError: () => {
      navigate(
        `/forbbiden${
          params.get("local") ? `?local=${params.get("local")}` : ""
        }`
      );
    },
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <SeekerContext.Provider value={data}>{children}</SeekerContext.Provider>
  );
};

export default AuthentecateSeeker;

export const useSeeker = () => {
  const seeker = useContext(SeekerContext);

  if (!seeker) {
    throw new Error("Can not be used outside AuthentecateSeeker");
  }

  return seeker;
};
