//import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import useAxios from "./useAxios";

interface UseFetchProps<D = unknown> {
  url: string;
  options?: {
    initialData?: D;
    onSuccess?: (data: D) => void;
    onError?: (error: any) => void;
    enabled?: boolean;
  };
}

interface UseFetchReturnType<D = unknown> {
  data: D | null;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  enable: () => void;
  refetch: (query: string) => void;
}

interface IResponse<D = unknown> {
  data: D[];
  message: string;
  status: boolean;
  token: any;
  total: number;
}

/**
 * Hook to fetch data from given url and handle fetch request life cycle.
 */
export function useFetch<D = unknown>(
  url: UseFetchProps["url"],
  options: UseFetchProps["options"] = {}
): UseFetchReturnType<D> {
  const { initialData = null, onSuccess, onError, enabled = true } = options;
  const axios = useAxios();
  const [isFetching, setIsFetching] = useState(enabled);
  const [isEnabled, setIsEnabled] = useState(enabled);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<D | null>(initialData as any);

  /**
   * function use to fetch data.
   * @access private
   */
  const fetchData = useCallback((query = "") => {
    setIsFetching(true);
    axios
      .get<IResponse<D>>(url + query)
      .then((response) => {
        setData(response.data?.data[0]);
        setIsSuccess(true);
        // check if onSuccess function exists, then execute it and pass response to it
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((error) => {
        setIsError(true);
        // check if onError function exists, then execute it and pass error to it
        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  /**
   * Use to make fetch conditionaly, make sure to make enabled option to false when use conditional fetch request.
   */
  const enable = useCallback(() => {
    setIsEnabled(true);
  }, []);

  /**
   * Refetch data.
   */
  const refetch = useCallback((query = "") => {
    fetchData(query);
  }, []);

  useEffect(() => {
    if (isEnabled) {
      fetchData();
    }
  }, [isEnabled]);

  return { data, isFetching, isError, isSuccess, enable, refetch };
}

export default useFetch;
