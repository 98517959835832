import React from "react";
import { Translator, useLocalization } from "src/lang";
import { cx } from "src/utils";
import styles from "./styles.module.scss";

const WaterMark = () => {
  return (
    <span className={cx("text", styles.watermark)}>
      <Translator textKey="watermark" />
    </span>
  );
};

export default WaterMark;
