import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalization } from "src/lang";
import { ILocal } from "src/lang/lang";
import { FC } from "src/types";

interface LocalizationSettingProps {
  children: React.ReactNode;
}

const LocalizationSetting: FC<LocalizationSettingProps> = ({ children }) => {
  const [params] = useSearchParams();
  const { setCode, direction, code } = useLocalization();

  useEffect(() => {
    setCode((params.get("local") || "en") as ILocal);
  }, []);

  return (
    <div style={{ direction }} className={code}>
      {children}
    </div>
  );
};

export default LocalizationSetting;
