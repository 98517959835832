import { ILang, getLang } from "./lang";
import { useLocalization } from "./Localization";
import { FC } from "src/types";

interface TranslatorProps {
  textKey: ILang;
}

const Translator: FC<TranslatorProps> = ({ textKey }) => {
  const { code } = useLocalization();
  const language = getLang(code);

  return <>{language[textKey] || null}</>;
};

export default Translator;
