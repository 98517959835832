import Axios from "axios";
import { API_URL } from "src/config";
import { redirect, useSearchParams } from "react-router-dom";
const useAxios = () => {
  const [queryParams] = useSearchParams();

  // * Create axios instance
  const axios = Axios.create({
    baseURL: API_URL,
    headers: {
      common: {
        Authorization: "Bearer " + queryParams.get("token"),
      },
    },
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      redirect("/");
      return Promise.reject(error);
    }
  );

  return axios;
};

export default useAxios;
