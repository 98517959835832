import React from "react";
import { ErrorMessage } from "../ErrorMessage";
import styles from "./styles.module.scss";
import { cx } from "src/utils";

const NotFound = () => {
  return (
    <div className={cx(styles.notFound)}>
      <ErrorMessage status="404" message="notFound" />
    </div>
  );
};

export default NotFound;
