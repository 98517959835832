import React from "react";
import { FC } from "src/types";
import { cx } from "src/utils";
import "./styles.scss";
import { ILang, Translator } from "src/lang";

interface TitleProps {
  variant?: "underline" | "defualt" | "fill";
  color?: "white" | "black";
  text: ILang;
}

const Title: FC<TitleProps> = ({
  variant = "underline",
  color = "white",
  text,
}) => {
  return (
    <h2 className={cx("title", "heading", variant, color)}>
      <Translator textKey={text} />
    </h2>
  );
};

export default Title;
