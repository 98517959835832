import { FC, ReactComponent } from "src/types";
import { cx } from "src/utils";
import styles from "./styles.module.scss";
import React from "react";
import WaterMark from "./WaterMark";

interface CvPageProps extends ReactComponent {}

const CvPage = React.forwardRef(
  ({ children, classes, ...props }: CvPageProps, ref) => {
    return (
      <div ref={ref as any} className={cx(styles.page, classes)} {...props}>
        {children}
        <WaterMark />
      </div>
    );
  }
);

export default CvPage;
