import { FC, ReactComponent } from "src/types";
import { cx } from "src/utils";
import styles from "./styles.module.scss";

interface AvatarProps extends Omit<ReactComponent, "children"> {
  src: string;
  alt: string;
  variant?: "circle" | "sharp";
}

const Avatar: FC<AvatarProps> = ({
  src,
  alt,
  variant = "circle",
  classes = "",
  ...props
}) => {
  return (
    <div className={cx(styles.avatar, styles[variant], classes)} {...props}>
      <img src={src} alt={alt} className={styles.avatar_image} />
    </div>
  );
};

export default Avatar;
