import { Providers } from "src/providers";
import { AppRoutes } from "src/routes";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Providers>
        <AppRoutes />
      </Providers>
    </div>
  );
}

export default App;
