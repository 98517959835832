import React from "react";
import { FC } from "src/types";
import { cx } from "src/utils";
import styles from "./styles.module.scss";

interface DescriptionProps {
  text: string;
}

const Description: FC<DescriptionProps> = ({ text }) => {
  return <p className={cx("text", styles.description)}>{text}</p>;
};

export default Description;
