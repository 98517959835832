import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ILang, ILangObj, ILocal, getLang } from "./lang";
import { FC } from "src/types";

interface ILocalization {
  // * => property that return current code language like en, ar etc.
  code: ILocal;
  // * => property that return current direction rtl or ltr.
  direction: "rtl" | "ltr";
  // * => property that return current language object in key value pair.
  language: ILangObj;
  // * => function that take key and return the translate for the key.
  translator: (key: ILang) => void;
  // * => function that used to set code
  setCode: (code: ILocal) => void;
}

const LocalizationContext = createContext<ILocalization>({
  code: "en",
  direction: "ltr",
  language: getLang("en"),
  translator: (key: ILang) => {},
  setCode: (code: ILocal) => {},
});

interface LocalizationProviderProps {
  langCode?: ILocal;
  defaultLanguage?: ILangObj;
  children: React.ReactNode;
}

export const LocalizationProvider: FC<LocalizationProviderProps> = ({
  langCode = "en",
  defaultLanguage = getLang("en"),
  children,
}) => {
  const [code, setCode] = useState(langCode);
  const [language, setLanguge] = useState(defaultLanguage);
  const direction = code === "en" ? "ltr" : "rtl";

  const translator = useCallback(
    (key: ILang) => {
      return language[key];
    },
    [language]
  );

  useEffect(() => {
    setLanguge(getLang(code));
  }, [code]);

  return (
    <LocalizationContext.Provider
      value={{ code, direction, language, setCode, translator }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);

  if (!context) {
    throw new Error(
      "useLocalization hook must be used inside LocalizationProvider."
    );
  }

  return context;
};
