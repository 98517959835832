import "./style.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className="loader-image">
        <img
          style={{ width: 70 }}
          src={'/images/logo-small-size.png'}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default Loader;
